import { isServer } from './isServer';

let mockedToken: null | string = null;

export function getToken(): string | null {
  // TODO: Cookies can help with this to enable SSR
  if (isServer()) return null;
  if (mockedToken) return mockedToken;
  try {
    return localStorage.getItem('token');
  } catch (error) {
    console.error(error);
  }
  return null;
}

export function mockGetToken(mocked: string | null) {
  mockedToken = mocked;
}
